import "./story.css";
import storyGIF from "../../assets/gif/Piggy.gif";

function Story() {
  return (
    <div className="story">
      <img src={storyGIF} alt="story" className="story-image" />
      <div className="content">
        <div className="text-content">
          <h1>Our Product’s Story</h1>
          <p>
            India is the developing country and young nation with largest number
            of GenZ and Millennials. When the world will be old in 2030 India
            will be at its highest potential with largest working population.
            Indias GDP and Foreign Investment is constantly increasing so this
            is the right time to invest In India
          </p>
          <a className="read-more" href="https://growyourpaisa.hashnode.dev/why-we-built-growyourpaisa" target="_blank" rel="noopener noreferrer">Read Our Complete Story &rarr;</a>
        </div>
      </div>
    </div>
  );
}

export default Story;

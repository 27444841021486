import "./newsletter.css";
import React, { useState, useEffect } from 'react';
import { AiFillCheckCircle } from "react-icons/ai";
import emailjs from 'emailjs-com';

function Newsletter() {
  const [email, setEmail] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const serviceId = 'service_8ijq5nx';
  const templateId = 'template_b9v6akj';
  const userId = '4b6qqIiTACVPEuk_g';

  useEffect(() => {
    let timeoutId;
    if (showFeedback) {
      timeoutId = setTimeout(() => {
        setShowFeedback(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showFeedback]);
  useEffect(() => {
    let timeoutId;
    if (showWarning) {
      timeoutId = setTimeout(() => {
        setShowWarning(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showWarning]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        emailjs.send(serviceId, templateId, { email }, userId).then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            setShowFeedback(true);
          },
          (error) => {
            console.log('FAILED...', error);
          }
        );
        setTimeout(() => {
          setShowFeedback(true);
        }, 1000);
      } else {
        setShowWarning(true);
      }
    }
  };


  return (
    <div className="newsletter">
      <h1 className="newsletter-heading">Sign Up Now</h1>
      <p className="newsletter-subheading">
      Grow your money by learning about various investment oppurtunities
      </p>
      <div className="newsletter-div">
          <form>
            <input
              type="email"
              aria-describedby="emailHelp"
              placeholder="Enter your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <button onClick={handleSubmit} className="banner-button-1">
              Sign Up
            </button>
          </form>
          {showFeedback && (
            <div className="fade-in-out2 show">
              Signed up successfully, Check your Email now 🤩
            </div>
          )}
          {showWarning && (
            <div className="fade-in-out2 warning">
              Please enter a valid email address.
            </div>
          )}
        </div>
    </div>
  );
}

export default Newsletter;

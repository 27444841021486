import React, { useState } from "react";
import "./buckets.css";
import BucketCard from "../../components/bucketCard/bucketCard";
import ShortTerm from "../../assets/png/shortterm.png";
import MediumTerm from "../../assets/png/mediumterm.png";
import HightRisk from "../../assets/png/highrisk.png";
import VeryHighRisk from "../../assets/png/veryhigh.png";
import FixedDeposit from "../../assets/png/fdIcon.png";
import Bonds from "../../assets/png/Bond.png";
import RecurringDeposit from "../../assets/png/rd.png";
import P2P from "../../assets/png/P2PIcon.png";
import LargeCap from "../../assets/png/lcmf.png";
import Nifty50 from "../../assets/png/nifty50.png";
import SndM from "../../assets/png/sndm.png";
import NPS from "../../assets/png/nps.png";
import Car from "../../assets/png/car.jpeg";
import Travel from "../../assets/png/travel.jpeg";
import House from "../../assets/png/house.jpeg";
import Retirement from "../../assets/png/retirement.jpeg";
import Topbar from "../../containers/topbar/topbar";

function Buckets() {
  const [activeTab, setActiveTab] = useState("investment");

  const investmentBuckets = [
    {
      image: ShortTerm,
      returns: "5-8% Returns",
      risk: "Low Risk",
      middleContent:
        "Short-term investments are financial instruments designed to provide returns within a relatively brief time frame, typically ranging from a few months to a couple of years. These investments prioritize liquidity and capital preservation while aiming to generate modest yet consistent gains.",
      option1: "Fixed Deposits",
      option2: "Recurring Deposits",
      optionImage1: FixedDeposit,
      optionImage2: RecurringDeposit,
      type: "investment",
    },
    {
      image: MediumTerm,
      returns: "8-11% Returns",
      risk: "Medium Risk",
      middleContent:
        "Mid-term investments are financial instruments that aim to provide moderate returns within a timeframe ranging from a few years to a decade. These investments bridge the gap between short-term investments, which prioritize liquidity, and long-term investments, which focus on maximizing returns over an extended period.",
      option1: "Bonds",
      option2: "P2P Lending",
      optionImage1: Bonds,
      optionImage2: P2P,
      type: "investment",

    },
    {
      image: HightRisk,
      returns: "11-15% Returns",
      risk: "High Risk",
      middleContent:
        "Long-term investments are designed to provide returns over an extended period, typically spanning several years or even decades. These investments prioritize capital appreciation and offer the potential for substantial gains through the power of compounding.",
      option1: "Large Cap MF",
      option2: "Nifty 50 MF",
      optionImage1: LargeCap,
      optionImage2: Nifty50,
      type: "investment",

    },
    {
      image: VeryHighRisk,
      returns: "15-20% Returns",
      risk: "High Risk",
      middleContent:
        "Very long-term investments refer to financial vehicles that span multiple decades, focusing on capital appreciation and wealth accumulation over an extended period. These investments are typically geared towards retirement planning, allowing individuals to harness the power of compounding.",
      option1: "Small And Mid Cap MF",
      option2: "National Pension Scheme",
      optionImage1: SndM,
      optionImage2: NPS,
      type: "investment",
    },
  ];
  const goalBuckets = [
    {
      image: House,
      returns: "Buy Your Dream House",
      risk: "10-15% Returns",
      middleContent:
        "Save and invest for your dream home, with an average tenure of 5-10 years depending on the property's cost and your savings rate.",
      option1: "Small And Mid Cap MF",
      option2: "Large Cap MF",
      optionImage1: SndM,
      optionImage2: LargeCap,
      type: "goal",

    },
    {
      image: Retirement,
      returns: "Plan Your Retirement",
      risk: "15-20% Returns",
      middleContent:
        "Secure your future by consistently saving for retirement, typically over a long-term period of 20-30 years.",
      option1: "Small And Mid Cap MF",
      option2: "Large Cap MF",
      optionImage1: SndM,
      optionImage2: LargeCap,
      type: "goal",

    },
    {
      image: Travel,
      returns: "Travel To Your Favourite Destination",
      risk: "8-12% Returns",
      middleContent:
        "Create unforgettable experiences by setting aside money for travel adventures, achievable within 1-5 years depending on the destination and your savings rate.",
      option1: "Bonds",
      option2: "Fixed Deposits",
      optionImage1: Bonds,
      optionImage2: FixedDeposit,
      type: "goal",

    },
    {
      image: Car,
      returns: "Car/Bike",
      risk: "10-12% Returns",
      middleContent:
        "Drive or ride in style by saving up for your dream vehicle, typically achievable within 2-7 years based on the cost and your savings rate.",
      option1: "Bonds",
      option2: "Nifty Fifty MF",
      optionImage1: Bonds,
      optionImage2: Nifty50,
      type: "goal",
    },
  ];
  const renderBuckets = activeTab === "investment" ? investmentBuckets : goalBuckets;

  return (
    <div className="Buckets-main">
      <Topbar showEarlyAccess={false} />
      <div className="tab-container">
      <button
          className={activeTab === "investment" ? "tab-button active" : "tab-button"}
          onClick={() => setActiveTab("investment")}
        >
          Investment Bucket
        </button>
        <button
          className={activeTab === "goal" ? "tab-button active" : "tab-button "}
          onClick={() => setActiveTab("goal")}
        >
          Goal Bucket
        </button>
   
      </div>
      <div className="Buckets">
        {renderBuckets.map((data) => (
          <BucketCard
            key={data.returns}
            image={data.image}
            returns={data.returns}
            risk={data.risk}
            middleContent={data.middleContent}
            option1={data.option1}
            option2={data.option2}
            optionCompany1={data.optionCompany1}
            optionCompany2={data.optionCompany2}
            optionImage1={data.optionImage1}
            optionImage2={data.optionImage2}
            type={data.type}
          />
        ))}
      </div>
    </div>
  );
}

export default Buckets;

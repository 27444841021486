import React, { useState, useEffect } from 'react';
import './banner.css';
import { AiFillCheckCircle } from 'react-icons/ai';
import emailjs from 'emailjs-com';
import mainBanner from '../../assets/svg/mainBanner.svg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function Banner() {
  const [email, setEmail] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const serviceId = 'service_8ijq5nx';
  const templateId = 'template_b9v6akj';
  const userId = '4b6qqIiTACVPEuk_g';

  useEffect(() => {
    let timeoutId;
    if (showFeedback) {
      timeoutId = setTimeout(() => {
        setShowFeedback(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showFeedback]);
  useEffect(() => {
    let timeoutId;
    if (showWarning) {
      timeoutId = setTimeout(() => {
        setShowWarning(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showWarning]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        emailjs.send(serviceId, templateId, { email }, userId).then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            setShowFeedback(true);
          },
          (error) => {
            console.log('FAILED...', error);
          }
        );
        setTimeout(() => {
          setShowFeedback(true);
        }, 1000);
      } else {
        setShowWarning(true);
      }
    }
  };

  return (
    <div className="banner">
      <div className="banner-left">
        <h1 className="banner-heading">Learn to Invest and grow your money</h1>
        <p className="banner-sub">Understand where you want to invest your money</p>
        <div className="banner-buttons">
          <form>
            <input
              type="email"
              aria-describedby="emailHelp"
              placeholder="Enter your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <button onClick={handleSubmit} className="banner-button-1">
              Sign Up
            </button>
          </form>
          {showFeedback && (
            <div className="fade-in-out show">
              Signed up successfully, Check your Email now 🤩
            </div>
          )}
          {showWarning && (
            <div className="fade-in-out warning">
              Please enter a valid email address.
            </div>
          )}
        </div>
      </div>
      <div className="banner-right">
        <div className="banner-image-container">
          <img className="main-image" src={mainBanner} alt="hero-image" />
        </div>
      </div>
    </div>
  );
}

export default Banner;

import React from "react";
import "./Footer.css";
import Logo from "../../assets/png/logo.svg";
import { FaFacebookF,FaTwitterSquare } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="footer-main">
      <img src={Logo} alt="logo" />
      <p className="footer-contributors">Made with ❤️ by <a className="text-blue" href="https://www.linkedin.com/in/akshay-gupta-7a433a122/" target="_blank" rel="noopener noreferrer">Akshay</a> and<a className="text-blue" href="https://www.linkedin.com/in/r-manoj/" target="_blank" rel="noopener noreferrer"> Manoj</a></p>
      <div className="social-media">
        <a href="https://www.facebook.com/people/GrowyourPaisa/100090785352147/" target="_blank" rel="noopener noreferrer">
        <FaFacebookF color="rgb(48,120,242)" />
        </a>
        <a href="https://www.linkedin.com/company/growyourpaisa/" target="_blank" rel="noopener noreferrer">
          <AiFillLinkedin color="#0077b5" />
        </a>
        <a href="https://twitter.com/paisagrows" target="_blank" rel="noopener noreferrer">
        <FaTwitterSquare color="#339BF0" />
        </a>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import "./steps.css";
import Step1 from "../../assets/png/step1.svg";
import Step2 from "../../assets/png/step2.svg";
import Step3 from "../../assets/png/step3.svg";

const HowItWorks = () => {
  return (
    <div>     
    <div className="how-it-works-container">
      <div className="how-it-works-steps">
        <div className="how-it-works-heading-div">
        <h1 className="how-it-works-heading">How GrowYourPaisa Works?</h1>
        </div>
        <div className="how-it-works-step-1">
        <div className="step-image1"> 
          <img src={Step1} alt="Step 1"  />
          </div>
          <div className="step-content-1">
            <h3>Choose your <span className="text-blue">goal</span></h3>
            <p>Based on your goal we suggest you the Investment category. These investment category is based on your goal, risk, return and tenure ( No. of Months or Years you want to invest your money.)</p>
          </div>
        </div>
        <div className="how-it-works-step-2">
          <div className="step-content-2">
            <h3>Learn about the <span className="text-blue">Investment</span></h3>
            <p>It’s very important to learn about the asset you are investing. You need to understand risk, returns, volatility and liquidity of the asset i.e can you withdraw the money anytime you want or is there any lock in period. </p>
          </div>
          <div className="step-image2">          
          <img src={Step2} alt="Step 2" />
          </div>

        </div>
        <div className="how-it-works-step-3">
        <div className="step-image1"> 
          <img src={Step3} alt="Step 3"  />
          </div>
          <div className="step-content-1">
            <h3>Start Investing and get <span className="text-blue">assured reward</span></h3>
            <p>Choose a trusted products which has a very good user experience and is safe and trusted by millions of people to start your investment journey</p>
          </div>
        </div>
      </div>
    </div>
    </div>

  );
};
export default HowItWorks;

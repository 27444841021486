import "./appsCard.css";
import Play from "../../assets/png/play.png"
import { AiOutlineLink } from 'react-icons/ai';
import {  useNavigate } from "react-router-dom";

function AppsCard({ title, image, content, option,risk,returns,reward }) {
  const navigate = useNavigate();
  const toComponentC = () => {
    navigate("/apps", { state: { risk: { risk }, returns: { returns },option:{option} } });
  };
  return (
    <div className="card-container">
    <div className="app-card">
      <img src={image} alt="app banner" />
      <div className="contents">
        <div className="content-top">
          <h2>{option}</h2>

          <div className="content-icons">
          <img src={Play} alt="Laptop" />
           <AiOutlineLink size={34} color="2e7ff8"/>
            {/* <img src={Laptop} alt="Laptop" />
            <img src={Android} alt="Android" />
            <img src={Iphone} alt="Iphone" /> */}
          </div>
        </div>
        <div className="contents-middle">
          <p>{content}</p>
          {/* <div className="app-feature">
            <p>0 Commision</p>
            <p>Mutual Funds</p>
            <p>Stocks</p>
          </div> */}
        </div>
        <p className="contents-middle-line">Apps you can use for Investing in this Category</p>
        <div className="contents-bottom">
          <h3>{title}</h3>
          <p>{reward}</p>
          <button>Download</button>
        </div>
        <button  onClick={() => {
      toComponentC();
    }} className="know-more">Know about other apps</button>

      </div>
    </div>
    </div>
  );
}

export default AppsCard;

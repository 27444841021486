import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/main/main";
import Buckets from "./pages/buckets/buckets";
import Apps from "./pages/apps/apps";
import Blogs from "./pages/blogs/blogs";
import InApps from "./pages/InApps/InApps"
import GoalApps from "./pages/apps/goalApps";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/buckets" element={<Buckets />} />
        <Route path="/investments" element={<Apps />} />
        <Route path="/goals" element={<GoalApps />} />
        <Route path="/apps" element={<InApps />} />
        <Route path="/Blogs" element={<Blogs />} />
      </Routes>
    </div>
  );
}

export default App;

import "./topbar.css";
import Logo from "../../assets/png/logo.svg";

function Topbar({showEarlyAccess}) {
  console.log(showEarlyAccess);
  const handleAccessButtonClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    });
  };

  return (
    <div className={`topbar`} style={{ top: 0, zIndex: 1 }}>
      <img src={Logo} alt="logo" className="logo" />
      {showEarlyAccess ? (
  <button className="access-button" onClick={handleAccessButtonClick}>
    Get Early access
  </button>
) : (
  null
)}

    </div>
  );
}

export default Topbar;

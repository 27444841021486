import "./main.css";
import Topbar from "../../containers/topbar/topbar";
import Banner from "../../containers/banner/banner";
import Features from "../../containers/features/features";
import Brands from "../../containers/brands/brands";
import Story from "../../containers/story/story";
import Services from "../../containers/servies/services";
import Steps from "../../containers/steps/steps";
import Newsletter from "../../containers/newsletter/newsletter";
import Footer from "../../containers/footer/Footer";
import Figures from "../../containers/figures/figures";

function Main() {
  return (
    <div className="App">
      <Topbar showEarlyAccess={true} />
      <Banner />
      <Steps />
      <Features />
      <Brands />
      <Story />
      {/* <Services /> */}
      {/* <Figures /> */}
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Main;

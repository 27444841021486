import React from "react";
import "./InApps.css";
import Play from "../../assets/png/play.png";
import { AiOutlineLink } from "react-icons/ai";

function InApps({
  title,
  image,
  content,
  option,
  reward,
  downloadUrl,
  youtubeUrl,
  link,
}) {
  const handleDownload = () => {
    window.open(downloadUrl, "_blank");
  };

  const handlePlayButtonClick = () => {
    if (youtubeUrl) {
      window.open(youtubeUrl, "_blank");
    }
  };

  const handleLinkOpen = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="card-container">
      <div className="app-card">
        <img src={image} alt="app banner" />
        <div className="contents">
          <div className="content-top">
            <h2>{option}</h2>
            <div className="content-icons">
              {youtubeUrl && (
                <img
                  src={Play}
                  alt="Play"
                  onClick={handlePlayButtonClick}
                  className="play-icon"
                />
              )}
              {link && (
                <AiOutlineLink onClick={handleLinkOpen} className="link-icon" />
              )}
            </div>
          </div>
          <div className="contents-middle">
            <p>{content}</p>
          </div>
          <div className="contents-bottom-in">
            <p>{reward}</p>
            <button onClick={handleDownload}>Download</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InApps;

import React, { useState, useEffect } from "react";
import "./InApps.css";
import InApps from "../../components/InApps/InApps";
import App1 from "../../assets/png/app1.png";
import App2 from "../../assets/png/app2.png";
import App3 from "../../assets/png/app3.png";
import App4 from "../../assets/png/app4.png";
import App5 from "../../assets/png/app5.png";
import App6 from "../../assets/png/app6.png";
import App7 from "../../assets/png/fi2.png";
import App8 from "../../assets/png/jar.jpeg";
import App9 from "../../assets/png/gullak.png";
import App10 from "../../assets/png/leapround.png";
import App11 from "../../assets/png/etmoney.webp";
import App12 from "../../assets/png/cashkaro.png";
import App13 from "../../assets/png/IndiaP2P.jpeg";
import App14 from "../../assets/png/univest.png";
import App15 from "../../assets/png/hubble.jpeg";
import App16 from "../../assets/png/stableMoney.png";
import App17 from "../../assets/png/Fello.png";
import { useLocation } from "react-router-dom";

function Apps() {
  const location = useLocation();
  const differentApps = [
    {
      image: App1,
      title: "Groww App",
      option: "Groww",
      reward: "Zero Comission",
      downloadUrl: "https://app.groww.in/v3cO/f0bk7vfq",
      youtubeUrl: "https://youtube.com/shorts/w8gaO1HNQnY?feature=share",
      content:
        "Groww is an investment app that allows users to invest in mutual funds, stocks, and other financial instruments. It provides a user-friendly interface, low fees, and educational resources to help users make informed investment decision.",
    },
    {
      image: App2,
      title: "Smallcase",
      option: "Smallcase",
      reward: "Get upto ₹300 & discounts",
      downloadUrl: "https://link.smallcase.com/join/2T0CK0",
      youtubeUrl: "https://youtu.be/CUAg2SnW1xI",
      content:
        "Invest in ideas with smallcases.They are modern investment products that help you build a diversified, low-cost & long term portfolio managed by professionals.",
    },
    {
      image: App3,
      title: "Zerodha",
      option: "Zerodha",
      reward: "Best User Experience",
      youtubeUrl: "https://youtu.be/uAAwy5aQxBs",
      downloadUrl: "https://zerodha.com/?c=JST182&s=CONSOLE",
      content:
        "Zerodha - India's biggest stock broker offering the lowest, cheapest brokerage rates for futures and options, commodity trading, equity and mutual funds",
    },
    {
      image: App4,
      title: "Wint Wealth",
      option: "Wint Wealth",
      reward: "Earn 9-11% fixed returns",
      downloadUrl: "",
      youtubeUrl: "https://youtu.be/HmWQPFvA4HQ",
      content:
        "Through Wint Wealth, you can invest in Bonds that give better returns than FDs but are less risky than Stocks. Start Investing at just ₹10000 & Earn 9-11%",
    },
    {
      image: App5,
      title: "Grip Invest",
      option: "Grip Invest",
      link: "https://www.gripinvest.in/#what-is-grip",
      reward: "Get upto ₹2000",
      downloadUrl:
        "https://www.gripinvest.in/?referralCode=MR3486&utm_medium=referralLink&utm_source=Copy",
      content:
        "Grip is a best alternative investments, technology-driven, transparency-focused digital investment platform for curated non-market linked alternative investments like bonds for earning fixed returns.",
    },
    {
      image: App6,
      title: "Jupiter",
      option: "Jupiter",
      reward: "Get upto ₹500",
      youtubeUrl: "https://youtu.be/nmFQyTwIl1M",
      downloadUrl: "https://jupiter.onelink.me/cizm/dm0kjqxk",
      content:
        "Jupiter is the easiest, personalised money management app that helps you take control of your finances. Earn upto 8% by investing in FDs and rewards on spends.",
    },
    {
      image: App7,
      title: "Fi Money",
      option: "Fi Money",
      reward: "Get upto ₹300",
      youtubeUrl: "https://youtu.be/zj85ANu91Z4",
      downloadUrl: "https://fi.onelink.me/GvZH/eow2s3gy",
      content:
        "Fi is an Indian financial app with features that help you get better with your money - track your expenses & invest in variety of assets. Earn upto 8% by investing in FDs and flat 10% by investing in FI Jump.",
    },
    {
      image: App8,
      title: "Jar App",
      option: "Jar App",
      reward: "Get up to ₹500 worth of digital gold",
      youtubeUrl: "https://youtu.be/Zq1j3-jfNqE",
      downloadUrl: "https://jar.onelink.me/6H9Q/xotb4in3",
      content:
        "Jar is a daily gold savings app that makes saving money a fun habit by saving a small amount of money every time you spend online.You can also earn extra 4% with Gold",
    },
    {
      image: App9,
      title: "Gullak",
      option: "Gullak",
      reward: "Get Flat ₹100 worth of digital gold",
      youtubeUrl: "https://youtu.be/d5e6_UFKm5U",
      downloadUrl: "https://gullakapp.page.link/mGd3vqgpLxM313dn9",
      content:
        "Gulak app, which is a personal finance app designed to help users to invest in digitial gold and reach their financial goals. Earn upto extra 5% with Gold",
    },
    {
      image: App10,
      title: "Leaf Round",
      option: "Leaf Round",
      reward: "Earn 18-22% interest",
      link: "https://leafround.com/",
      downloadUrl: "https://refer.leafround.com/24NIAN4",
      content:
        "Leaf Round is a marketplace for exclusive, high-yield, fixed-income opportunities.Earn upto 18-22% XIRR and stable monthly returns by investing in fixed income instruments.",
    },
    {
      image: App11,
      title: "ET Money",
      option: "ET Money",
      reward: "Save upto 2 lakh rupees on taxes",
      link: "https://www.etmoney.com/nps",
      downloadUrl: "https://etmoney.onelink.me/unJQ/krzze2xr",
      content:
        "ET Money is India's most loved personal finance platform. Invest in Direct Mutual Funds, Stocks & National Pension Scheme to save more taxes.",
    },
    {
      image: App12,
      title: "Cash Karo",
      option: "Cash Karo",
      reward: "Earn upto 30% extra cashback on online shopping",
      youtubeUrl: "https://youtu.be/FRQDBgw6dDMs",
      downloadUrl: "https://cashk.app.link/mxUM7iw0FBb",
      content:
        "CashKaro is India’s #1 Cashback and Coupons App, where you can get up to 30% EXXXTRA Cashback on all your Online Shopping from 1000+ sites like Amazon, Myntra, Flipkart & more",
    },
    {
      image: App13,
      title: "India P2P",
      option: "India P2P",
      reward: "Earn Upto ₹500 ",
      youtubeUrl: "https://youtube.com/shorts/bEi86BgFQME?feature=share",
      downloadUrl: "https://invest.indiap2p.com/register?ref=MR5E7Z",
      content:
        "IndiaP2P is a best informing Peer to Peer Lending platform in India regulated by RBI.It offers the ideal investment product for retail investors with predictable returns upto 18%.",
    },
    {
      image: App14,
      title: "Univest",
      option: "Univest",
      reward: "Earn upto 12% per annum without lock-in",
      youtubeUrl: "https://youtube.com/shorts/8zeGGph6UQQ?feature=share",
      downloadUrl: "https://univest.page.link/Czbt",
      content:
        "Earn upto 12% per annum and withdraw anytime with Univest Elite in partnership with Faircent which is India`s first and largest RBI-regulated NBFC-P2P",
    },
    {
      image: App15,
      title: "Hubble",
      option: "Hubble",
      reward: "Earn upto ₹100",
      youtubeUrl: "https://youtube.com/shorts/jBG4BOWF0E0?feature=share",
      downloadUrl: "https://save.hbbl.in/r/referral",
      content:
        "With Hubble Money, every time you spend, you get more. Get up to 10% instant discounts across your beloved brands, without the hassle of hunting for deals or missing out on offers. It's as simple as it sounds – spend more, save more!",
    },
    {
      image: App16,
      title: "Stable Money ",
      option: "Stable Money ",
      reward: "Earn ₹250 Amazon Giftcard",
      youtubeUrl:
        "https://assets.stablemoney.in/web-frontend/app-video/find_fd_vkhhux.mp4",
      downloadUrl: "https://stablemoney.app.link/6lQDT6ddMBb",
      content:
        "Stable Money is a fixed-return investment platform that allows users to access fixed deposits, debt mutual funds, and bonds.Unlock returns up to 8.85% by discovering high-interest FDs",
    },
    {
      image: App17,
      title: "Fello ",
      option: "Fello ",
      reward: "Get Upto ₹100",
      youtubeUrl: "https://www.getfinzie.com/",
      downloadUrl: "https://fello.in/app/referral/zPfe",
      content:
        "Fello is India's first game based savings app. You can grow your money and earn weekly rewards just by saving in secure assets!",
    },
  ];

  const [appsArray, setAppsArray] = useState([]);
  const [showFilters, setShowFilters] = useState(true);

  const handleClearFilters = () => {
    setAppsArray(differentApps);
    setShowFilters(false);
  };

  useEffect(() => {
    // Determine the risk level based on the location state
    const riskLevel = location?.state?.risk?.risk;

    // Create separate arrays for each risk level
    const lowRiskApps = [differentApps[0], differentApps[1]];
    const mediumRiskApps = [differentApps[2], differentApps[3]];
    const highRiskApps = [differentApps[4], differentApps[5], differentApps[6]];

    // Determine the apps array based on the risk level
    if (riskLevel === "Low Risk") {
      setAppsArray(lowRiskApps);
    } else if (riskLevel === "Medium Risk") {
      setAppsArray(mediumRiskApps);
    } else if (riskLevel === "High Risk") {
      setAppsArray(highRiskApps);
    } else {
      setAppsArray(differentApps);
    }
  }, [location]);

  return (
    <div className="Apps-main">
      <div className="dropdowns">
        <div className="dropdown-heads">
          <p>Risk</p>
          <p>Return</p>
          <h5 onClick={handleClearFilters}>Clear filters</h5>
        </div>
        {showFilters && (
          <div className="dropdown-values">
            <div className="risk">{location?.state?.risk?.risk}</div>
            <div className="return">{location?.state?.returns?.returns}</div>
            <div className="return">{location?.state?.option?.option}</div>
          </div>
        )}
      </div>
      <div className="Apps">
        {appsArray.map((x) => (
          <InApps
            key={x.title}
            image={x.image}
            title={x.title}
            content={x.content}
            option={x.option}
            reward={x.reward}
            downloadUrl={x.downloadUrl}
            youtubeUrl={x.youtubeUrl}
            link={x.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Apps;

import "./features.css";
import featureImage from "../../assets/png/feature.png";
import BondImage from "../../assets/png/Bonds.png";
import FdImage from "../../assets/png/Fd.png";
import GoldImage from "../../assets/png/Gold.png";
import RealestateImage from "../../assets/png/Realestate.png";
import StocksImage from "../../assets/png/Stocks.png";
import TechnologyImage from "../../assets/png/Technology.png";

function Features() {
  return (
    <div className="feature">
      <h1 className="feature-head">
        Grow your Money Wisely, Start Creating Wealth
      </h1>
      <p className="feature-sub">
        Know about various investment opportunities based on risk, return and
        time to save, protect or grow your money
      </p>
      <div className="features">
        <div className="feature-block">
          <img src={featureImage} alt="Mutual Funds" />
          <h1>Mutual Funds</h1>
          <p>Managed by experts, mutual funds offer varied risks and returns.</p>
        </div>
        <div className="feature-block">
          <img src={BondImage} alt="Bonds" />
          <h1>Bonds</h1>
          <p>
            A way in which government and private companies raise money from the
            public.
          </p>
        </div>
        <div className="feature-block">
          <img src={FdImage} alt="FDs/RDs" />
          <h1>FDs/RDs</h1>
          <p>
            Protect your money with your parents' favorite investment option.
          </p>
        </div>
        <div className="feature-block">
          <img src={StocksImage} alt="Stocks" />
          <h1>P2P Landing</h1>
          <p>
          Lending money to individuals or businesses and earn upto 18% returns.
          </p>
        </div>
        <div className="feature-block">
          <img src={featureImage} alt="Smallcase" />
          <h1>Stocks</h1>
          <p>Invest in direct stocks, but requires effort to learn and invest in
            the right stock.</p>
        </div>
        <div className="feature-block">
          <img src={TechnologyImage} alt="Technology" />
          <h1>Technology</h1>
          <p>
          Get high returns by investing in startups starting from 5000 Rs.
          </p>
        </div>
        <div className="feature-block">
          <img src={RealestateImage} alt="Real Estate" />
          <h1>Real Estate</h1>
          <p>Invest in RIET a company that owns and typically operates income-producing real estate or related assets.</p>
        </div>
        <div className="feature-block">
          <img src={GoldImage} alt="Digital Gold" />
          <h1>Digital Gold</h1>
          <p>Invest in digital gold for safe and secure investments.</p>
        </div>
      </div>
    </div>
  );
}

export default Features;

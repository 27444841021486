import React, { useState, useEffect } from "react";
import "./apps.css";
import AppsCard from "../../components/appsCard/appsCard";
import App1 from "../../assets/png/fixedDeposit.jpeg";
import App2 from "../../assets/png/recurringdeposit.jpeg";
import App3 from "../../assets/png/bondBanner.png";
import App4 from "../../assets/png/digitalGold.png";
import App5 from "../../assets/png/largeMF.jpeg";
import App6 from "../../assets/png/p2p.png";
import App7 from "../../assets/png/midcapmf.png";
import App8 from "../../assets/png/niftymf.jpeg";
import App9 from "../../assets/png/smallmf.jpeg";
import App10 from "../../assets/png/assetleasing.png";
import App11 from "../../assets/png/invoicediscounting.jpeg";
import App12 from "../../assets/png/goldbond.jpeg";
import App13 from "../../assets/png/npsBanner.png";
import App14 from "../../assets/png/cashback.webp";
import FundImage from "../../assets/png/fundType.png";
import { useLocation } from "react-router-dom";

function Apps() {
  const location = useLocation();
  const differentApps = [
    {
      image: App1,
      title: "Jupiter",
      reward:"₹500 Reward",
      option: "Fixed Deposit",
      content:
        "A type of savings account where you deposit a lump sum of money for a fixed period of time and earn a fixed rate of interest.",
    },
    {
      image: App2,
      title: "Fi Money",
      reward:"Upto ₹100 Reward",      
      option: "Recurring Deposits",
      content:
        "A type of savings account where you deposit a fixed amount of money every month for a fixed period of time and earn a fixed rate of interest.",
    },
    {
      image: App6,
      title: "IndiaP2P",
      reward:"Earn 1% Bonus",      
      option: "P2P Lending",
      content:
        "A type of investment where you lend money to individuals or businesses through an online platform in exchange for regular interest payments.",
    },

    {
      image: App4,
      title: "Jar",
      reward:"₹50 Reward",      
      option: "Digital Gold",
      content:
        "A type of investment in which you buy and own physical gold in digital form.",
    },
    {
      image: App5,
      title: "Groww",
      reward:"No Commission & charges",
      option: "Large Cap MF",
      content:
        "A type of mutual fund that invests in large, well-established companies with a proven track record.",
    },
    {
      image: App3,
      title: "Grip Invest",
      option: "Bonds",
      reward:"Upto ₹2000 Reward",      
      content:
        "A type of fixed-income investment where you lend money to a company or government in exchange for regular interest payments and the return of your principal at the end of the bond term.",
    },
    {
      image: App7,
      title: "Groww",
      reward:"No Commission & charges",
      option: "Mid Cap MF",
      content:
        "A type of mutual fund that invests in mid-sized companies with moderate growth potential.",
    },
    {
      image: App8,
      title: "Groww",
      reward:"No Commission & charges",
      option: "Nifty 50 MF",
      content:
        "A type of mutual fund that invests in the 50 largest companies listed on the National Stock Exchange (NSE).",
    },
    {
      image: App9,
      title: "Groww",
      reward:"No Commission & charges",
      option: "Small Cap MF",
      content:
        "A type of mutual fund that invests in small, emerging companies with high growth potential.",
    },

    {
      image: App11,
      title: "Leaf Round",
      reward:"Earn 18-22% interest",
      option: "Invoice Discounting",
      content:
        "In Invoice discounting companies sell unpaid invoices to a investor and they give you a cash advance that's a percentage of the invoice's value and also earn return upto 15%.",
    },
    {
      image: App13,
      title: "ET Money",
      reward:"Save upto 2 lakhs rs on taxes",
      option: "National Pension Scheme",
      content:
        "It provides a tax-efficient way to build a retirement corpus while allowing individuals to choose their investment strategy, with an additional deduction of an extra Rs 50,000.",
    },
    {
      image: App12,
      title: "Wintwealth",
      reward:"Extra 3% returns",
      option: "Sovereign Gold Bond",
      content:
        "Sovereign Gold Bonds, government-issued investment instruments, enable gold investment without physical ownership, with an additional interest of up to 2.5%",
    },
    {
      image: App10,
      title: "Leaf Round",
      reward:"Earn 18-22% interest",
      option: "Asset Leasing",
      content:
        "Asset leasing is a financial instruments that allows you to diversify your investments and early stable monthly rental income.",
    },
    {
      image: App14,
      title: "CashKaro",
      reward:"Save upto 30% on shopping",
      option: "Extra Cashback",
      content:
        "Unlock extra cashback on top of existing offers when you shop online at popular brands like Amazon, Flipkart, and Myntra. Maximize your savings with every purchase!",
    },

  ];

  const [appsArray, setAppsArray] = useState([]);
  const [showFilters, setShowFilters] = useState(true);

  const handleClearFilters = () => {
    setAppsArray(differentApps);
    setShowFilters(false);
  };

  useEffect(() => {
    // Determine the risk level based on the location state
    const riskLevel = location?.state?.risk?.risk;
    // Create separate arrays for each risk level
    const lowRiskApps = [differentApps[0], differentApps[1],differentApps[2]];
    const mediumRiskApps = [differentApps[3], differentApps[4]];
    const highRiskApps = [differentApps[5], differentApps[6],differentApps[7]];
    const vhighRiskApps = [differentApps[8]];


    // Determine the apps array based on the risk level
    if (riskLevel === "Low Risk") {
      setAppsArray(lowRiskApps);
    } else if (riskLevel === "Medium Risk") {
      setAppsArray(mediumRiskApps);
    } else if (riskLevel === "High Risk") {
      setAppsArray(highRiskApps);
    } else if (riskLevel === "Very High Risk")    {
      setAppsArray(vhighRiskApps);
    } 

  }, [location]);

  return (
    <div className="Apps-main">
      <div className="dropdowns">
        <div className="dropdown-heads">
          <p>Risk</p>
          <p>Return</p>
          <h5 onClick={handleClearFilters}>Clear filters</h5>
        </div>
        {showFilters && (
          <div className="dropdown-values">
            <div className="risk">{location?.state?.risk?.risk}</div>
            <div className="return">{location?.state?.returns?.returns}</div>
          </div>
        )}
      </div>

      <div className="Apps">
        {appsArray.map((x) => (
          <AppsCard
            key={x.title}
            image={x.image}
            title={x.title}
            content={x.content}
            option={x.option}
            reward={x.reward}
            risk={location?.state?.risk?.risk}
            returns={location?.state?.returns?.returns}
          />
        ))}
      </div>
    </div>
  );
}

export default Apps;

import "./brands.css";
import Brand1 from "../../assets/png/brand1.png";
import Brand2 from "../../assets/png/brand2.png";
import Brand3 from "../../assets/png/brand3.svg";
import Brand4 from "../../assets/png/brand4.png";

function Brands() {
  return (
    <div className="brands">
      <h1 className="brand-heading">
      Products trusted by millions of people to invest their money
        </h1>
      <div className="brands-div">
        <img src={Brand1} alt="brand" />
        <img src={Brand2} alt="brand" />
        <img src={Brand3} alt="brand" />
        <img src={Brand4} alt="brand" />
      </div>
    </div>
  );
}

export default Brands;

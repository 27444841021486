import "./bucketCard.css";
import BucketImage from "../../assets/png/bucket.png";
import MutualFunds from "../../assets/mutualFunds.png";
import Groww from "../../assets/png/Groww.png";
import { Link, useNavigate } from "react-router-dom";

function BucketCard(props) {
  const navigate = useNavigate();
  const { risk, returns, middleContent, option1, option2, optionImage1, optionCompany1, optionCompany2, optionImage2, image, type } = props;
  const toComponentB = () => {
    navigate("/investments", { state: { risk: { risk }, returns: { returns } } });
  };
  return (
    <div
      onClick={() => {
        toComponentB();
      }}
      className="bucket-card"
    >
      <div className="bc-left">
        <img src={image} alt="bucket-image" />
      </div>
      <div className="bc-right">
        <div className="bc-right-top">
          <h3>{returns}</h3>
          <p>{risk}</p>
        </div>
        <div className="bc-right-middle">
          <p>{middleContent}</p>
        </div>
        <div className={`${type  === "investment" ? "bc-right-bottom": "bc-right-bottom margin-top"}`}>
          {type === "investment" ? <h3>Top options in this Investment bucket</h3> : <h3>Top investment options for this goal</h3>
          }

          <div className="bc-investment-options">
            <div>
              <div class="avatar">
                {optionImage1 && <img src={optionImage1} alt="avatar" class="img-circle-small" />}
              </div>
              <p>{option1}</p>
            </div>
            <div>
              <div class="avatar">
                {optionImage2 && <img src={optionImage2} alt="avatar" class="img-circle-small" />}
              </div>
              <p>{option2}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BucketCard;
